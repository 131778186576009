<template>
  <div style="position: relative;height: 215px;">
    <quill-editor :value="value"
      ref="myQuillEditor"
      :options="editorOption"
      @change="onEditorChange($event)"
      v-loading="loading">
    </quill-editor>
    <el-upload
      action="https://upload.qiniup.com"
      :before-upload='beforeUpload'
      :on-success='uploadSuccess'
      :data="uploadData"
      accept="image/*"
      ref="imageupload"
      style="display:none">
      <el-button id="imgInput" size="small" type="primary">点击上传</el-button>
    </el-upload>
    <el-upload
      action="https://upload.qiniup.com"
      :before-upload='beforeUpload'
      :on-error="handleError"
      :on-success='uploadSuccess'
      :data="uploadData"
      accept="video/*"
      ref="videoupload"
      style="display:none">
      <el-button id="videoInput" size="small" type="primary">点击上传</el-button>
    </el-upload>
  </div>
</template>

<script>
import axios from 'axios'
import { quillEditor } from 'vue-quill-editor'

const http = axios.create()
export default {
  name: 'editor',
  model: {
    prop: 'value',
    event: 'changes'
  },
  props: {
    value: String,
    placeholder: String
  },
  components: {
    quillEditor
  },
  data () {
    return {
      editorOption: {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }],
            ['link', 'image', 'video']
          ]
        },
        placeholder: ''
      },
      uploadData: {
        token: ''
      },
      loading: false
    }
  },
  created () {
    if (this.placeholder) this.editorOption.placeholder = this.placeholder
  },
  mounted: function () {
    // 为图片ICON绑定事件  getModule 为编辑器的内部属性
    this.$refs.myQuillEditor.quill.getModule('toolbar').addHandler('image', this.imgHandler)
    this.$refs.myQuillEditor.quill.getModule('toolbar').addHandler('video', this.videoHandler)
  },
  methods: {
    // 上传前获取token
    beforeUpload (file) {
      this.loading = true
      return http.get('/api/upload/token?path=image').then(resp => {
        if (resp.data.code === 0) {
          this.uploadData.token = resp.data.data.token
        }
      })
    },
    // 上传成功
    uploadSuccess (e, file, fileList) {
      const url = e.url
      this.loading = false
      const type = file.raw.type.split('/')[0]
      const range = this.$refs.myQuillEditor.quill.getSelection()
      if (url !== null && url.length > 0) {
        this.$refs.myQuillEditor.quill.insertEmbed(range.index, type, url)
      } else {
        this.$notify({
          title: '失败',
          message: '插入失败',
          type: 'error'
        })
      }
      this.$refs[type + 'upload'].clearFiles()
    },
    // 上传失败
    handleError () {
      this.loading = false
    },
    // 点击图片ICON触发事件
    imgHandler (state) {
      if (state) {
        const fileInput = document.getElementById('imgInput')
        fileInput.click()
      }
    },
    // 点击视频ICON触发事件
    videoHandler (state) {
      if (state) {
        const fileInput = document.getElementById('videoInput')
        fileInput.click()
      }
    },
    // v-model绑定
    onEditorChange ({ html }) {
      this.$emit('changes', html)
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.ql-editor.ql-blank::before {
  font-style: normal;
  color: #c0c4cc;
}
/deep/.quill-editor {
  height: 170px;
  line-height: 1;
}
</style>
